import { memo } from 'react';
import { cn } from '@divlab/divanui';

import useMedias from '@Hooks/useMedias';
import Img from '@UI/Img';
import Text from '@Promo/elems/Text';
import FontAnton from '@Components/FontAnton';
import styles from './CategoryBannerATRTemplate.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { CategoryBannerData } from '@Promo/typings';

export interface CategoryTemplateProps extends HTMLAttributes<HTMLDivElement> {
  banner: CategoryBannerData;
}

const CategoryBannerATRTemplate: FC<CategoryTemplateProps> = (props) => {
  const { banner, title, ...restProps } = props;
  const { isMobile } = useMedias();
  const resources = banner.resources.default;
  const image = isMobile ? resources.mobileImage : resources.desktopImage;
  const text = banner.text;

  return (
    <div className={styles.banner} style={{ backgroundColor: banner.color }} {...restProps}>
      {image && <Img className={cn(styles.image)} src={image} loading='eager' />}

      <div className={styles.container}>
        {text?.title && (
          <>
            <FontAnton />
            {title ? (
              <Text className={styles.title} desktop={title} isMobile={isMobile} isH1 />
            ) : (
              text?.title && (
                <Text
                  className={styles.title}
                  desktop={text.title}
                  mobile={text.titleMobile}
                  isMobile={isMobile}
                  isH1
                />
              )
            )}
          </>
        )}

        {text?.promocode && (
          <Text
            className={styles.promocode}
            desktop={text.promocode}
            mobile={text.promocodeMobile}
            isMobile={isMobile}
          />
        )}

        {text?.subtitle && (
          <Text
            className={styles.subtitle}
            desktop={text.subtitle}
            mobile={text.subtitleMobile}
            isMobile={isMobile}
          />
        )}
      </div>
    </div>
  );
};

export default memo(CategoryBannerATRTemplate);
