import { memo, useState } from 'react';
import { cn } from '@divlab/divanui';

import useMedias from '@Hooks/useMedias';
import Img from '@UI/Img';
import Video from '@Promo/elems/Video';
import Text from '@Promo/elems/Text';
import styles from './CategoryBannerWideTemplate.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { CategoryBannerData } from '@Promo/typings';

export interface CategoryBannerWideTemplateProps extends HTMLAttributes<HTMLDivElement> {
  banner: CategoryBannerData;
}

const CategoryBannerWideTemplate: FC<CategoryBannerWideTemplateProps> = (props) => {
  const { banner, title, ...restProps } = props;
  const [muted] = useState(true);
  const { isMobile } = useMedias();
  const resources = banner.resources.default;
  const image = isMobile ? resources.mobileImage : resources.desktopImage;
  const isVideo = !!resources.desktopVideo;
  const text = banner.text;

  return (
    <div
      className={cn(styles.banner, {
        [styles.inversed]: banner.inversed,
      })}
      {...restProps}
    >
      {isVideo ? (
        <Video
          className={styles.imageContainer}
          src={isMobile ? resources.mobileVideo : resources.desktopVideo}
          poster={isMobile ? resources.mobileImage : resources.desktopImage}
          muted={muted}
          playsInline
          autoPlay
          loop
        />
      ) : (
        <Img className={styles.imageContainer} cnImage={styles.image} src={image} loading='eager' />
      )}

      <div className={styles.container}>
        {title ? (
          <Text className={styles.title} desktop={title} isMobile={isMobile} isH1 />
        ) : (
          text?.title && (
            <Text
              className={styles.title}
              desktop={text.title}
              mobile={text.titleMobile}
              isMobile={isMobile}
              isH1
            />
          )
        )}

        {text?.subtitle && (
          <Text
            className={styles.subtitle}
            desktop={text.subtitle}
            mobile={text.subtitleMobile}
            isMobile={isMobile}
          />
        )}

        {text?.condition && (
          <Text
            className={styles.condition}
            desktop={text.condition}
            mobile={text.conditionMobile}
            isMobile={isMobile}
          />
        )}
      </div>
    </div>
  );
};

export default memo(CategoryBannerWideTemplate);
