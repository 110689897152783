import { memo } from 'react';

import CategoryBannerTemplate from './elems/CategoryBannerTemplate';
import CategoryBannerPromocodeTemplate from './elems/CategoryBannerPromocodeTemplate';
import CategoryBannerATRTemplate from './elems/CategoryBannerATRTemplate';
import CategoryBannerWideTemplate from './elems/CategoryBannerWideTemplate';
import CategoryBannerYandexTemplate from './elems/CategoryBannerYandexTemplate';

import type { FC, HTMLAttributes } from 'react';
import type { CategoryBannerData } from '@Promo/typings';

export interface CategoryBannerProps extends HTMLAttributes<HTMLDivElement> {
  banner: CategoryBannerData;
  title?: string;
}

const CategoryBanner: FC<CategoryBannerProps> = (props) => {
  const { banner, title, ...restProps } = props;
  const template = banner.template || 'default';

  switch (template) {
    case 'default': {
      return <CategoryBannerTemplate banner={banner} title={title} {...restProps} />;
    }

    case 'promocode': {
      return <CategoryBannerPromocodeTemplate banner={banner} title={title} {...restProps} />;
    }

    case 'atr': {
      return <CategoryBannerATRTemplate banner={banner} title={title} {...restProps} />;
    }

    case 'wide': {
      return <CategoryBannerWideTemplate banner={banner} title={title} {...restProps} />;
    }

    case 'yandex': {
      return <CategoryBannerYandexTemplate banner={banner} title={title} {...restProps} />;
    }
  }
};

export default memo(CategoryBanner);
