import { memo } from 'react';
import { cn } from '@divlab/divanui';

import useMedias from '@Hooks/useMedias';
import Img from '@UI/Img';
import Text from '@Promo/elems/Text';
import Logo from '@Promo/elems/Logo';
import styles from './CategoryBannerYandexTemplate.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { CategoryBannerData } from '@Promo/typings';

export interface CategoryBannerYandexTemplateProps extends HTMLAttributes<HTMLDivElement> {
  banner: CategoryBannerData;
}

const CategoryBannerYandexTemplate: FC<CategoryBannerYandexTemplateProps> = (props) => {
  const { banner, title, ...restProps } = props;
  const { isMobile } = useMedias();
  const resources = banner.resources.default;
  const logo = banner.resources.default.logo;
  const image = isMobile ? resources.mobileImage : resources.desktopImage;
  const text = banner.text;
  const alignmentX = banner.alignmentX;
  const alignmentY = banner.alignmentY;

  return (
    <div
      className={cn(styles.banner, {
        [styles.inversed]: banner.inversed,
        [styles.inversedLogo]: banner.inversedLogo,
      })}
      {...restProps}
    >
      {image && (
        <Img className={styles.imageContainer} cnImage={styles.image} src={image} loading='eager' />
      )}

      <div
        className={cn(styles.container, {
          [styles.centerCenter]: alignmentX === 'center' && alignmentY === 'center',
          [styles.centerTop]: alignmentX === 'center' && alignmentY === 'top',
          [styles.leftCenter]: alignmentX === 'left' && alignmentY === 'center',
          [styles.leftTop]: alignmentX === 'left' && alignmentY === 'top',
        })}
      >
        {logo && <Logo className={styles.logo} src={logo} size='M' />}

        {text.period && (
          <Text
            className={styles.period}
            desktop={text.period}
            mobile={text.periodMobile}
            isMobile={isMobile}
          />
        )}

        {title ? (
          <Text
            className={logo ? styles.logoTitle : styles.title}
            desktop={title}
            isMobile={isMobile}
            isH1
          />
        ) : (
          text?.title && (
            <Text
              className={logo ? styles.logoTitle : styles.title}
              desktop={text.title}
              mobile={text.titleMobile}
              isMobile={isMobile}
              isH1
            />
          )
        )}

        {text?.subtitle && (
          <Text
            className={styles.subtitle}
            desktop={text.subtitle}
            mobile={text.subtitleMobile}
            isMobile={isMobile}
          />
        )}

        {text?.condition && (
          <Text
            className={styles.condition}
            desktop={text.condition}
            mobile={text.conditionMobile}
            isMobile={isMobile}
          />
        )}
      </div>
    </div>
  );
};

export default memo(CategoryBannerYandexTemplate);
